@charset "UTF-8";
 

h1 {
	font-size: 24px !important;
	font-weight: 400 !important;
	line-height: 32px !important;
}

h2 {
	font-size: 20px !important;
	line-height: 32px !important;
}

.mat-mdc-button:disabled {
    color: var(--mdc-text-button-disabled-label-text-color, rgba(0, 0, 0, 0.38)) !important;
}

mat-form-field {
	.mdc-text-field--filled:not(.mdc-text-field--disabled) {
		background-color: white;
	}
}